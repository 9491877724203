import React, { Component } from 'react'
import styled from '@emotion/styled'
import { css } from '@emotion/core'
import Grid from 'src/components/Grid'
import Input from 'src/components/Input'
// import TextArea from 'src/components/TextArea'
import TextArea from 'src/components/Input'
import TextLockup from 'src/components/TextLockup'
import Button from 'src/components/Button'
import ScrollEntrance from 'src/components/ScrollEntrance'
import { util } from 'src/styles'
import { validateEmail, validatePhoneNumber } from 'src/utils/validations'
import { withAppState } from 'src/state/AppState'

const FormSection = styled.div`
  text-align: center;
  width: 90vw;
  ${ util.responsiveStyles('max-width', 800, 600, 600, 600) }
  ${ util.responsiveStyles('padding-top', 60, 60, 40, 30) }
  ${ util.responsiveStyles('padding-bottom', 80, 80, 50, 40) }
  ${ util.responsiveStyles('padding-left', 80, 80, 50, 30) }
  ${ util.responsiveStyles('padding-right', 80, 80, 50, 30) }
`

const FormIntro = styled(TextLockup)`
  ${ util.responsiveStyles('margin-bottom', 30, 30, 24, 24) }
  p {
    // max-width: 20em;
    margin-left: auto;
    margin-right: auto;
  }
`

class CateringForm extends Component {
  constructor (props) {
    super(props)
    this.submitForm = this.submitForm.bind(this)
    this.state = {
      status: '',
      name: '',
      phone: '',
      eventDate: '',
      partySize: '',
      email: '',
      eventDetails: '',
    }
  }

  handleInput = event => {
    this.setState({ [event.target.name]: event.target.value })
  }

  render() {
    const {
      className,
      theme,
      prevTheme,
      nextTheme,
      isFirstSection,
      appContext,
    } = this.props
    const formSpacing = '14px'

    const { toggleModal, modal } = appContext

    const {
      status,
      name,
      phone,
      eventDate,
      email,
      eventDetails,
      partySize,
    } = this.state

    if (status === 'SUCCESS' && modal !== false) {
      toggleModal(false)
    }

    const valid =
      name !== '' &&
      email !== '' &&
      phone !== '' &&
      eventDate !== '' &&
      partySize !== '' &&
      validateEmail(email)
      validatePhoneNumber(phone)
    return (
      <FormSection
        className={className}
        prevTheme={prevTheme}
        nextTheme={nextTheme}
        setTheme={theme}
        isFirstSection={isFirstSection}
      >
        <FormIntro
          headline="Contact"
          headlineSize='h1'
          text="Whether you want to bring your event to The Hugh or have our restaurants come to you, we have dining options for every occasion. Reach out to our team for more information about weddings, corporate events, private parties, catering, and more by filling out the form below."
        />
        <div>
          <form
            onSubmit={this.submitForm}
            action={'https://formspree.io/' + process.env.GATSBY_FORMSPREE_ID}
            method="POST"
          >
            <Grid small="[1]" rowGap={formSpacing} as={ScrollEntrance}>
              <Grid small="[1]" medium='[1] [1]' rowGap={formSpacing} colGap={formSpacing}>
                <div>
                  <Input
                    onChange={this.handleInput}
                    name="name"
                    value={name}
                    label="Name"
                  />
                </div>
                <div>
                  <Input
                    onChange={this.handleInput}
                    name="email"
                    value={email}
                    label="Email"
                    type="email"
                    isValid={email !== '' ? validateEmail(email) : true}
                  />
                </div>
              </Grid>
              <Grid small="[1]" rowGap={formSpacing}>
              <div>
                  <Input
                    onChange={this.handleInput}
                    name="phone"
                    value={phone}
                    label="Phone"
                    type="tel"
                  />
                </div>
                <div>
                  <Input
                    onChange={this.handleInput}
                    name="eventDate"
                    value={eventDate}
                    label="Event/Catering Date"
                    type="text"
                  />
                </div>
                <div>
                  <Input
                    onChange={this.handleInput}
                    name="partySize"
                    value={partySize}
                    label="Party Size"
                    type="number"
                  />
                </div>
                <div>
                  <Input
                    onChange={this.handleInput}
                    name="eventDetails"
                    value={eventDetails}
                    label="Tell us a little about what you have in mind."
                  />
                </div>
                <div style={{ paddingTop: formSpacing }}>
                  <Button type="submit" disabled={!valid} size='large'>
                    {status === "SUCCESS" ? "Thank You" : "Submit"}
                  </Button>
                </div>
              </Grid>
            </Grid>
          </form>
        </div>
      </FormSection>
    )
  }

  submitForm (ev) {
    ev.preventDefault()
    const form = ev.target
    const data = new FormData(form)
    const xhr = new XMLHttpRequest()
    xhr.open(form.method, form.action)
    xhr.setRequestHeader('Accept', 'application/json')
    xhr.onreadystatechange = () => {
      if (xhr.readyState !== XMLHttpRequest.DONE) return
      if (xhr.status === 200) {
        this.setState({ status: "SUCCESS" })
        setTimeout(() => {
          this.setState({
            status: '',
            name: '',
            eventDate: '',
            location: '',
            email: '',
            restaurant: ''
          })
          form.reset()
        }, 2000)
      } else {
        this.setState({ status: 'ERROR' })
      }
    }
    xhr.send(data)
  }
}

export default withAppState(CateringForm)
